<script setup>
import {
  BCard,
  BModal,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormTextarea,
  },

  data() {
    return {
      showEmailModal: false,
      emailForm: {
        subject: "",
        to: "",
        body: "",
      },
    };
  },
  props: ["showModal", "to", "close", "ok"],
  methods: {
    async sendMail() {
      this.$props.close();
      this.$props.ok(this.emailForm);
      this.resetEmailForm();
    },

    resetEmailForm() {
      this.emailForm.value = {
        subject: "",
        to: "",
        body: "",
      };
    },
  },

  watch: {
    showEmailModal(newValue) {
      if (!newValue) {
        this.resetEmailForm();
        this.$props.close();
      }
    },

    showModal: {
      deep: true,
      handler(newValue, oldValue) {
        this.showEmailModal = this.$props.showModal;
        this.emailForm.to = this.$props.to;
      },
    },
  },

  beforeMount() {
    this.showEmailModal = this.$props.showModal;
  },
};
</script>

<template>
  <!-- Modal -->
  <b-modal v-model="showEmailModal" size="md" hide-footer title="Send An Email">
    <form class="form gap-2 pa-5" @submit.prevent="sendMail">
      <b-row style="gap: 10px 0">
        <b-col cols="12">
          <b-form-input
            required
            placeholder="Subject"
            label="Subject"
            v-model="emailForm.subject"
          />
        </b-col>
        <b-col cols="12">
          <b-form-input
            required
            placeholder="To"
            label="To"
            v-model="emailForm.to"
          />
        </b-col>
        <b-col cols="12">
          <b-form-textarea
            required
            placeholder="Message"
            label="Message"
            v-model="emailForm.body"
            rows="4"
          />
        </b-col>
        <b-col cols="12">
          <b-row class="justify-content-end pr-1" style="gap: 0 10px">
            <b-button
              variant="danger"
              @click="
                () => {
                  showEmailModal = false;
                  emailForm = {
                    subject: '',
                    to: '',
                    body: '',
                  };
                }
              "
            >
              Close
            </b-button>
            <b-button type="submit" variant="primary">Send Mail</b-button>
          </b-row>
        </b-col>
      </b-row>
    </form>
  </b-modal>
  <!-- End Modal -->
</template>

